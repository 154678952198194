<template lang="pug">
modal(size='sm' @close='$emit("close")')
  form-header
    span(v-html='$t("Edit Tags")')

  div(v-if='tags.length > 0' class='bleed')
    simple-list(:items='tags' class='mb-2')
      list-item(v-for='(tag, index) in tags' :key='tag.name')
        b {{ tag.name }}
        template(#actions)
          div(class='inline-flex' :data-tooltip='$t("Move Up")')
            btn(faded small data-test='btn-move-tag-up' icon='arrow-up' :disabled='tags.length === 1 || index === 0' @click='moveUp(index, tags)')
          div(class='inline-flex' :data-tooltip='$t("Move Down")')
            btn(faded small data-test='btn-move-tag-down' icon='arrow-down' :disabled='tags.length === 1 || index === tags.length - 1' @click='moveDown(index, tags)')
          btn(
            faded
            data-test='btn-edit-tag'
            small
            icon='custom-edit'
            :data-tooltip='$t("Rename Tag")'
            @click.prevent='$utils.routeQueryAdd({ "form-project-tag-edit": { projectId: id, tagName: tag.name, size: "sm" } })'
          )
          btn(faded data-test='btn-delete-tag' small icon='trash' :data-tooltip='$t("Delete Tag")' @click='() => deleteTag(tag.name)')

  empty-state(v-else :centered='true')
    template(#message)
      span {{ $t('There are no tags in this project yet.') }}
    template(#buttons)
      btn(secondary icon='plus' data-test='btn-add-tag' @click='$utils.routeQueryAdd({ "form-project-tag-create": id })') {{ $t('Add Tag') }}
  form-footer
    template(#secondary)
      btn(secondary icon='plus' data-test='btn-add-tag' @click='$utils.routeQueryAdd({ "form-project-tag-create": id })') {{ $t('Add Tag') }}
</template>

<script lang="ts">
import { useProjectsStore } from '../store'
import { type ITag } from '../types'
import { useConfirm } from '@/plugins/Confirm'
import { arrayMoveImmutable } from 'array-move'
import { defineComponent } from 'vue'
import { computed } from 'vue'

const FormProjectTagsEdit = defineComponent({
  inheritAttrs: false,
  props: { id: { type: String, required: true } },
  setup(props) {
    const { confirm } = useConfirm()
    const store = useProjectsStore()
    return {
      tags: computed(() => store.project(props.id)?.tagList.tags || []),
      moveUp: (index: number, items: ITag[]) =>
        index > 0 &&
        store.updateTagOrder(
          props.id,
          arrayMoveImmutable(
            items.map((tag) => tag.name),
            index,
            index - 1,
          ),
        ),
      moveDown: (index: number, items: ITag[]) =>
        index < items.length - 1 &&
        store.updateTagOrder(
          props.id,
          arrayMoveImmutable(
            items.map((tag) => tag.name),
            index,
            index + 1,
          ),
        ),
      deleteTag: (tags: any) =>
        confirm({
          onOk: () => store.removeTags(props.id, tags),
        }),
    }
  },
})

export default FormProjectTagsEdit
</script>
